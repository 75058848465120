import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements as gnxCatalogueWcomponent } from 'gnx-catalogue-wcomponent/loader';
import { defineCustomElements as gnxOrdersWcomponent } from 'gnx-orders-wcomponent/loader';
import { defineCustomElements as GnxNequiWcomponent } from 'gnx-nequi-wcomponent';
import { defineCustomElements as gnxOnboardingWcomponent } from 'gnx-onboarding-wcomponent/loader';
import { defineCustomElements as GnxGamificationWcomponent } from 'gnx-gamification-wcomponent';
import { defineCustomElements as GnxSocialNetworkWcomponent } from 'gnx-social-network-wcomponent/loader';
import { defineCustomElements as GnxChallengesWcomponent } from 'gnx-challenges-wcomponent/loader';

if (environment.production) {
  enableProdMode();
  window.console.log =
    window.console.warn =
    window.console.info =
      function () {};
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));

gnxCatalogueWcomponent(window);
gnxOrdersWcomponent(window);
GnxNequiWcomponent(window);
gnxOnboardingWcomponent(window);
GnxGamificationWcomponent(window);
GnxSocialNetworkWcomponent(window);
GnxChallengesWcomponent(window);
