import { RedemptionService } from 'src/app/services/redemption/redemption.service';
import { PushNotificationsManagerService } from './../push-notifications-manager/push-notifications-manager.service';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  HostListener,
} from '@angular/core';
import { MenuController, ModalController, Platform } from '@ionic/angular';
//Services
import { RouterService } from 'src/app/services/router/router.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from '../../services/user/user.service';
import { LanguagesService } from '../../services/languagesService/languages.service';
//Interfaces
import { ISettingsMenu } from '../../interfaces/ISettings';
import { IMenu } from '../../interfaces/ILangs';
import { TagsService } from 'src/app/services/tags/tags.service';
import { Event, NavigationEnd, Router } from '@angular/router';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { RemoteConfigService } from 'src/app/services/remote-config/remote-config.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { CanExchangeService } from 'src/app/services/can-exchange/can-exchange.service';
import { CanExChange } from 'src/app/interfaces/ICanExchange';
import { AlertService } from 'src/app/services/alert/alert.service';
import { Plugins } from '@capacitor/core';
const { Clipboard } = Plugins;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  showMenu = 'my-custom-menu';
  swipeMenu = true;
  type = 'overlay';
  LOADER_ID = 'menu';
  name: string;
  // texts IU for language
  textsIUMenu: IMenu[];
  settingsMenu: ISettingsMenu[];
  @Input() points: string = '1';
  widthBody: number = 0;
  @ViewChild('ionMenu') ionMenu: any;
  @Input() enableRedemption: boolean = false;
  @Input() set isRedemptionActive(value) {
    this.isActive = value;
    this.iconLockRedemption = value
      ? '../../../assets/icons/home/open_lock.svg'
      : '../../../assets/icons/home/close_lock.svg';
  }
  isActive;
  iconLockRedemption;
  isRouterValidAlertLimit;
  notificationsNumber = 0;
  notificationUnViewed;
  productsShoppingCart = 0;
  phoneContact: string;
  apiWhatsapp: string = 'https://api.whatsapp.com/send?phone=';
  getScreenWidth: any;
  getScreenHeight: any;
  canExchange: any;
  padlock = false;
  rewardsCode: string | number;
  constructor(
    public routerService: RouterService,
    private menuCtr: MenuController,
    private authService: AuthService,
    private userService: UserService,
    public languageService: LanguagesService,
    private tagsService: TagsService,
    public platform: Platform,
    private redemptionService: RedemptionService,
    public router: Router,
    private pushNotificationsManagerService: PushNotificationsManagerService,
    private shoppingCartService: ShoppingCartService,
    private utilsService: UtilsService,
    private remoteConfig: RemoteConfigService,
    private canExchangeService: CanExchangeService,
    private alertService: AlertService,
    private localStorage: StorageService
  ) {}
  get getTextIUCurrency() {
    //console.log(this.languageService.textsIU)
    return 'Puntos';
  }

  async ngOnInit() {
    this.getDataProfileField();
    this.getInfoUser();
    this.getPoints();
    this.canExchanges();
    this.widthBody = document.querySelector('body').clientWidth;
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.isRouterValidAlertLimit =
          event.url.includes('home') || event.url.includes('catalogue');
      }
    });
    this.pushNotificationsManagerService.numberViewed$.subscribe((element) => {
      this.notificationUnViewed = element;
    });
    if (this.getScreenWidth >= 650) {
      this.swipeMenu = false;
      this.showMenu = 'my-custom-menu show-menu';
      this.type = 'reveal';
    } else {
      this.swipeMenu = true;
      this.showMenu = 'my-custom-menu';
      this.type = 'overlay';
    }
    // const product = await this.shoppingCartService.getCart();
    this.shoppingCartService.numberProducts$.subscribe((element) => {
      this.productsShoppingCart = element;
    });
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth >= 650) {
      this.swipeMenu = false;
      this.showMenu = 'my-custom-menu show-menu';
      this.type = 'reveal';
    } else {
      this.swipeMenu = true;
      this.showMenu = 'my-custom-menu';
      this.type = 'overlay';
    }
  }
  async onClick() {
    // this.showMenu = "my-custom-menu "
  }

  async getDataProfileField() {
    const dataProfile = await this.localStorage.get('gnx-claims');
    this.rewardsCode = dataProfile.profile.codigorewards[0];
  }

  getPoints() {
    this.utilsService.getUserPoints().then(async (points: any) => {
      this.points = points;
    });
  }

  async canExchanges() {
    this.canExchange = await this.canExchangeService.getCanExchange();
    this.canExchange = this.canExchange.code;
    if (this.canExchange === CanExChange.EXCHANGE_DISABLED_BY_PADLOCK) {
      this.padlock = true;
    }
    return;
  }

  redirectTo(route, name) {
    console.log('route', route);
    this.menuCtr.close();
    this.ionMenu.close();
    this.setTags(name);
    if (!route) {
      this.pushNotificationsManagerService.deleteInfoCache();
      return this.authService.logout();
    }

    this.routerService.redirectRoot(route);
  }

  async getInfoUser() {
    this.userService.getUserInfo().subscribe((res: any) => {
      if (res) {
        this.name = res.name + ' ' + res.lastnames;
      }
    });
  }
  changeLanguage(language: string) {
    this.languageService.loadLanguage(language);
  }
  get getTextsIUMenu() {
    if (this.widthBody > 575)
      return this.languageService.textsIU.menu_web.filter(
        (item) => item.active
      );
    // console.log(this.languageService.textsIU.menu)
    return this.languageService.textsIU.menu.filter((item) => item.active);
  }

  async openRedemptionInfo() {
    if (this.isActive) return;
    if (!this.redemptionService.IsOpen)
      return this.redemptionService.openModal('menu');
  }

  setTags(id: number) {
    /*     switch (id) {
      case 1:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_profile_clicked",
          false,
          true
        );
        break;
      case 2:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_orders_clicked",
          false,
          true
        );
        break;
      case 3:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_indicators_clicked",
          false,
          true
        );
        break;
      case 4:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_status_clicked",
          false,
          true
        );
        break;
      case 5:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_privacy_clicked",
          false,
          true
        );
        break;
      case 6:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_config_clicked",
          false,
          true
        );
        break;
      case 7:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_terms_clicked",
          false,
          true
        );
        break;
      case 7:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_logout_clicked",
          false,
          true
        );
        break;

      default:
        console.log("no se ha encontrado event tag");
        break;
    } */
  }

  getTagName(idMenu) {
    const directory = {
      1: 'menu_profile_clicked',
      2: 'menu_orders_clicked',
      3: 'menu_indicators_clicked',
      4: 'menu_status_clicked',
      5: 'menu_privacy_clicked',
      6: 'menu_config_clicked',
      7: 'menu_terms_clicked',
      8: 'menu_logout_clicked',
    };
    return directory[idMenu];
  }
  setNumber(number) {
    this.notificationsNumber = number;
  }
  openShoppingCart() {
    this.routerService.redirectRoot('/app/shopping-cart');
  }

  async infoLock() {
    console.log('appppp');
    return this.canExchangeService.openModal('exchange');
  }

  async support_chat() {
    const result = await this.remoteConfig.getInfoApp().toPromise();
    this.phoneContact = result['phone-contact'];
    this.apiWhatsapp = `${this.apiWhatsapp}${this.phoneContact}&text=¡Hola!`;
    location.href = this.apiWhatsapp;
  }
}
