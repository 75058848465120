import { PushNotificationsManagerService } from './../../components/push-notifications-manager/push-notifications-manager.service';
import { EmblueService } from './../emblue/emblue.service';
import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpHeaders, HttpClient } from '@angular/common/http';
//import { JwtHelperService } from "@auth0/angular-jwt";
import { tap, first } from 'rxjs/operators';
import firebase from 'firebase/app';
import * as moment from 'moment';

import { environment } from 'src/environments/environment';
//Services
import { StorageService } from '../storage/storage.service';
import { RouterService } from '../router/router.service';
import { PlatformService } from '../platform/platform.service';
import { LoaderService } from '../loader/loader.service';
import { AlertService } from '../alert/alert.service';
import { ExceptionsService } from 'src/app/exceptions/exceptions.service';
import { UserService } from '../user/user.service';
import { SafeScript } from '@angular/platform-browser';
import { IParamsAuth } from 'src/app/interfaces/IParamsAuth';
import { UtilsService } from 'src/app/services/utils/utils.service';
import {
  IRegisterUser,
  ICheckUser,
  IRegisterConfig,
  IChangePassword,
  IUpdateProfile,
} from 'src/app/interfaces/IAuth';
import { ModalController } from '@ionic/angular';
const { Storage } = Plugins;

//const helperJWT = new JwtHelperService();
const LOGIN_WITH_USER_AND_PASSWORD_AUTHENTICATION =
  'LOGIN_WITH_USER_AND_PASSWORD_AUTHENTICATION';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  fbTokenJWT: string;
  headers = new HttpHeaders();
  currentAuthTokenValue: string;
  firebaseUid;
  recaptchaVerifier;
  //  methodAuth: string;
  emailGoogle: string;
  userGnxId: string = '';
  apiKey: string;
  programId;
  //component register
  mode: string = 'initial';
  showPasswordInput: boolean = false;
  methodAuth: string;
  phone;
  gnxCredential1;

  paramsAuth: IParamsAuth = { oobCode: '', mode: '' };
  constructor(
    private platformService: PlatformService,
    private angularFireAuthService: AngularFireAuth,
    private http: HttpClient,
    private alertService: AlertService,
    private exceptionService: ExceptionsService,
    private storageService: StorageService,
    private loaderService: LoaderService,
    private routerService: RouterService,
    private utilService: UtilsService,
    private emblueService: EmblueService,
    private pushNotificationsManagerService: PushNotificationsManagerService,
    private modalController: ModalController
  ) {
    this.headers = this.headers.append('X-Skip-Interceptor', '');
    this.saveGlobalUser();
  }
  async saveGlobalUser() {
    const id = await this.storageService.get('gnx-account-id');
    const claims = await this.storageService.get('gnx-claims');
    if (claims) {
      const programId = claims.programs;
      const phone = claims.phone;
      if (id && programId) {
        this.userGnxId = id;
        this.programId = programId;
        //this.utilService.setGlukyUserId(id);
      }
      this.phone = claims.phone ? claims.phone.replace('+57', '') : null;
      this.gnxCredential1 = claims.username;
    }
  }

  loginWithProvider(provider: string) {
    this[`${provider}Login`]();
    // aca puede ir un evento de anlytics
  }

  googleLogin() {
    // if (this.checkPlatform()) {
    //   this.loginWeb(firebase.auth.GoogleAuthProvider);
    // } else {
    //   this.loginNative();
    // }
    // this.angularFireAuthService.signInWithRedirect()
  }

  checkPlatform() {
    return (
      this.platformService.checkPlatform('mobileweb') ||
      this.platformService.checkPlatform('desktop')
    );
  }

  async loginWeb(authProvider) {
    const provider = new authProvider();
    this.oauthLogin(provider);
  }
  private async oauthLogin(provider) {
    try {
      const credential = await this.angularFireAuthService.signInWithPopup(
        provider
      );
      // this.userService.userLogin(credential);
    } catch (err) {
      throw new Error(err);
    }
  }

  async loginNative() {
    try {
      let googleUser: any = await Plugins.GoogleAuth.signIn(null);
      let middleCredential = firebase.auth.GoogleAuthProvider.credential(
        googleUser.authentication.idToken
      );
      // const credential = await this.angularFireAuthService.signInAndRetrieveDataWithCredential(
      //   googleUser
      // );
      // this.userService.userLogin(middleCredential);
    } catch (err) {
      console.log(err);
    }
  }
  async callFbToken() {
    this.fbTokenJWT = await firebase.auth().currentUser.getIdToken();
    return this.fbTokenJWT;
  }

  getAccessFromUsers(fbTokenJWT, username?): Promise<any> {
    console.log('getAccessFromUsers fbTokenJWT -> ', fbTokenJWT);
    console.log('getAccessFromUsers username -> ', username);

    const { headers } = this;
    const { app_id, apiKeyWeb } = environment.gluky;

    return new Promise((resolve, reject) =>
      this.fetchAccessJwt(
        app_id,
        apiKeyWeb,
        headers,
        fbTokenJWT,
        username
      ).subscribe(
        async (res: {
          match: boolean;
          gn_token: string;
          users?: [];
          account: any;
        }) => {
          console.log('getAccessFromUsers fetchAccessJwt res, ', res);
          if (res.account && res.account.personal_data) {
            await Storage.set({
              key: 'gnx-personal-data',
              value: JSON.stringify(res.account.personal_data),
            });
          }
          if (res.users && res.users.length > 0) {
            console.log(
              'getAccessFromUsers fetchAccessJwt resolve res.users , ',
              res
            );
            resolve(res.users);
          }
          if (res.match && res.gn_token) {
            console.log(
              'getAccessFromUsers fetchAccessJwt resolve res.gn_token , ',
              res
            );
            await this.saveUserGlobalId(res.account.id);
            resolve(res.gn_token);
          }
        },
        (err) => {
          console.error('fetchAccessJwt err, ', err);
          reject(err);
        }
      )
    );
  }
  fetchAccessJwt(
    app_id: string,
    apikey: string,
    headers: any,
    fbTokenJWT: any,
    username?: any
  ) {
    const params: any = {
      app_id,
      app_jwt: fbTokenJWT,
    };
    if (username && username != undefined && username != null) {
      params.username = username;
    }

    return this.http.post(
      `${environment.gluky.usersApiBasePath}/auth/access-jwt?key=${apikey}`,
      {
        ...params,
      },
      {
        headers,
      }
    );
  }

  checkAccessFromUsers(username, password) {
    const params = {
      username,
      password,
      app_id: environment.gluky.app_id,
    };
    const apikey = this.apiKey;
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.gluky.usersApiBasePath}/auth/access-jwt?key=${apikey}`,
          {
            ...params,
          }
        )
        .subscribe(
          (ans) => resolve(ans),
          (err) => reject(err)
        );
    });
  }
  linkUserOnGnx(fbTokenJWT: any, username: any, password: any) {
    const params = {
      username,
      password,
      app_id: environment.gluky.app_id,
      app_jwt: fbTokenJWT,
    };
    const apikey = this.apiKey;
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.gluky.usersApiBasePath}/auth/access-jwt?key=${apikey}`,
          {
            ...params,
          }
        )
        .subscribe(
          async (ans: any) => {
            await this.saveUserGlobalId(ans.account.id);
            resolve(ans);
          },
          (err) => reject(err)
        );
    });
  }
  linkEmailOnGnx(fbTokenJWT: any, email: string) {
    const params = {
      email,
      app_id: environment.gluky.app_id,
      app_jwt: fbTokenJWT,
    };
    const apikey = this.apiKey;
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.gluky.usersApiBasePath}/auth/access-jwt?key=${apikey}`,
          {
            ...params,
          }
        )
        .subscribe(
          async (ans: any) => {
            await this.saveUserGlobalId(ans.account.id);
            resolve(ans);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  linkPhoneOnGnx(fbTokenJWT, phone) {
    const params = {
      phone,
      app_id: environment.gluky.app_id,
      app_jwt: fbTokenJWT,
    };
    const apikey = this.apiKey;
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.gluky.usersApiBasePath}/auth/access-jwt?key=${apikey}`,
          {
            ...params,
          }
        )
        .subscribe(
          async (ans: any) => {
            console.log('ans', ans);

            await this.saveUserGlobalId(ans.account.id);
            resolve(ans);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  async saveUserGlobalId(uid) {
    await this.storageService.set('gnx-account-id', uid);
    this.userGnxId = uid;
  }

  registerNewEmailAndPassword(email, password) {
    return this.angularFireAuthService.createUserWithEmailAndPassword(
      email,
      password
    );
  }
  async removeUserFromFirebase() {
    const user: firebase.User = await this.angularFireAuthService.currentUser;
    return await user.delete();
  }

  async unsubscribeUserFromGnx() {
    this.loaderService.presentLoading('unsubscribeUserFromGnx');
    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.gluky.usersApiBasePath}/auth/unsubscribe`, {})
        .subscribe(
          async (ans: any) => {
            resolve(ans);
            this.loaderService.cancelLoader('unsubscribeUserFromGnx');
          },
          (err) => {
            reject(err);
            this.loaderService.cancelLoader('unsubscribeUserFromGnx');
            this.exceptionService.renderError(err);
          }
        );
    });
  }

  async logout() {
    await this.angularFireAuthService.signOut();
    await this.storageService.removeAll();
    localStorage.clear();
    sessionStorage.clear();
    this.currentAuthTokenValue = '';
    // console.log("### logout ###");

    await this.pushNotificationsManagerService.deleteToken();
    this.pushNotificationsManagerService.deleteInfoCache();

    this.currentAuthTokenValue = '';
    this.modalController.dismiss(null, null, 'modal-terms-conditions');
    this.modalController.dismiss(null, null, 'modal-privacy-policies');
    setTimeout(() => {
      window.location.reload();
    }, 300);
    this.routerService.Out('/login');
    this.routerService.navigate('login', { replaceUrl: true });
  }
  verifyTimeStampToken = () => {
    const tokenTimeStamp = this.utilService.parseJwt(
      this.currentAuthTokenValue
    ).exp;
    const actualDate = moment().unix();

    return tokenTimeStamp <= actualDate;
  };
  async verifyEmail(params) {
    // console.log(params.oobCode);
    return this.angularFireAuthService.applyActionCode(params.oobCode);
  }
  async refreshToken() {
    let token = await this.storageService.get('gnx-token');
    if (!token) {
      return null;
    }
    this.currentAuthTokenValue = token;
    return this.verifyTimeStampToken()
      ? this.refreshTokenAndSaveOnLocalStorage()
      : token;
  }
  refreshTokenAndSaveOnLocalStorage = async () => {
    try {
      const fbtokenJWT = await this.callFbToken();
      const newToken = await this.getAccessFromUsers(fbtokenJWT);
      await this.storageService.set('gnx-token', newToken);
      this.currentAuthTokenValue = newToken;
      return newToken;
    } catch (err) {
      return err;
    }
  };

  async setCurrentTokenGnx(token: any) {
    console.log('setCurrentTokenGnx', token);

    this.currentAuthTokenValue = token;
  }
  prepareRecaptcha(buttonId: string) {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(buttonId, {
      size: 'invisible',
      callback: (response: any) => {},
      'expired-callback': function (responseOnCancel: any) {
        console.log(responseOnCancel);
      },
    });
  }
  makeLoginWithPhone(phoneNumber) {
    return new Promise((resolve, reject) => {
      this.angularFireAuthService
        .signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier)
        .then((confirm) => {
          resolve(confirm);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  // components register
  async verifyUsingEmail(email: string, password: string) {
    if (this.mode === 'emailOnUse' && password.length) {
      console.log('entro en email');
      try {
        await this.loginWithUserAndPassword(email, password);
      } catch (err) {
        this.exceptionService.renderError(err);
      }
    } else {
      console.log('Checkear correo');
      this.checkEmail(email);
    }
  }
  async checkEmail(email: string) {
    this.loaderService.presentLoading('register');
    try {
      if (!(await this.checkIfEmailIsOnUse(email))) {
        this.loaderService.cancelLoader('register');
        this.storageService.set('email-to-register', email);
        this.routerService.redirect('register-password');
        return;
      }
      this.alertService.presentAlert(
        'Este correo ya tiene usuarios activos, ingresa la contrasenia correcta para conectarte de nuevo'
      );
      this.mode = 'emailOnUse';
      this.showPasswordInput = true;
      this.loaderService.cancelLoader('register');
    } catch (err) {
      this.exceptionService.renderError(err);
      this.loaderService.cancelLoader('register');
    }
  }
  async checkIfEmailIsOnUse(email) {
    console.log('verificando email', email);
    const emailVerification =
      await this.angularFireAuthService.fetchSignInMethodsForEmail(email);
    console.log(emailVerification.length);
    return emailVerification.length;
  }

  async deleteUserAuthFirebase() {
    const user: firebase.User = await this.angularFireAuthService.currentUser;
    return await user.delete();
  }
  getUSerInfoFromFirebase() {
    return this.angularFireAuthService.authState
      .pipe(tap(), first())
      .toPromise();
  }

  verifyPasswordResetCode(actionCode) {
    return new Promise((resolve, reject) => {
      this.angularFireAuthService
        .verifyPasswordResetCode(actionCode)
        .then((confirm) => {
          resolve(confirm);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  confirmPasswordReset(actionCode, newPass) {
    return new Promise((resolve, reject) => {
      this.angularFireAuthService
        .confirmPasswordReset(actionCode, newPass)
        .then((confirm) => {
          resolve(confirm);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  getParamsAuth(emailVerification: string) {
    try {
      console.log(emailVerification);
      const indexMode = emailVerification.indexOf('&mode') + 6;
      const indexObbCode = emailVerification.indexOf('&oobCode') + 9;
      const indexContinueUrl = emailVerification.indexOf('&continueUrl');

      this.paramsAuth.oobCode = emailVerification.substring(
        indexObbCode,
        indexContinueUrl
      );
      this.paramsAuth.mode = emailVerification.substring(
        indexMode,
        indexObbCode - 9
      );
      console.log(this.paramsAuth);
    } catch (error) {
      console.error(
        'Error al tratar de obtener params Auth en aplicacion nativa'
      );
    }
  }
  async setApiKeyByPlatform() {
    const plat = this.platformService.getPlatform();
    if (
      plat.includes('mobileweb') ||
      plat.includes('desktop') ||
      plat.includes('tablet')
    ) {
      return (this.apiKey = environment.gluky.apiKeyWeb);
    }
    if (plat.includes('pwa')) {
      return (this.apiKey = environment.gluky.apikeyPwa);
    }
    if (plat.includes('android')) {
      return (this.apiKey = environment.gluky.apiKeyAndroid);
    }
    if (plat.includes('ios')) {
      //TODO add paike to ios
      return (this.apiKey = environment.gluky.apiKeyAndroid);
    }
  }
  // NEW AUTH

  registerUser(data: IRegisterUser) {
    const apikey = environment.gluky.apikey;

    return this.http.post(
      `${environment.gluky.usersApiBasePath}/auth/register?key=${apikey}`,
      {
        ...data,
      }
    );
  }

  checkIfRegistered(data: ICheckUser) {
    const apikey = environment.gluky.apikey;

    return this.http.post(
      `${environment.gluky.usersApiBasePath}/auth/check-if-registered?key=${apikey}`,
      {
        ...data,
      }
    );
  }

  async loginWithUserAndPassword(user: string, password: string) {
    const AUTHENTICATION = await this.storageService.get(
      LOGIN_WITH_USER_AND_PASSWORD_AUTHENTICATION
    );
    if (AUTHENTICATION) {
      return AUTHENTICATION;
    }

    const authentication =
      await this.angularFireAuthService.signInWithEmailAndPassword(
        user,
        password
      );
    this.storageService.set(
      LOGIN_WITH_USER_AND_PASSWORD_AUTHENTICATION,
      authentication
    );
    return authentication;
  }

  getRegisterConfigData(data: IRegisterConfig) {
    const apikey = environment.gluky.apikey;

    return this.http.post(
      `${environment.gluky.usersApiBasePath}/auth/register-config-data?key=${apikey}`,
      {
        ...data,
      }
    );
  }

  changePassword(data: IChangePassword) {
    const apikey = environment.gluky.apikey;
    const headers = new HttpHeaders({ Authorization: `Bearer ${data.token}` });

    return this.http.post(
      `${environment.gluky.usersApiBasePath}/auth/change-password?key=${apikey}`,
      {
        new_password: data.password,
      },
      {
        headers,
      }
    );
  }

  updateProfile(data: IUpdateProfile) {
    const apikey = environment.gluky.apikey;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.fbTokenJWT}`,
    });

    return this.http.put(
      `${environment.gluky.usersApiBasePath}/auth/update-user?key=${apikey}`,
      {
        ...data,
      },
      {
        headers,
      }
    );
  }

  async getPublicDataProfile() {
    let claim = await this.storageService.get('gnx-claims');
    return this.http.get(
      `${environment.gluky.usersApiBasePath}/frontend/users/${claim.uid}/public-data`
    );
  }

  async sendFcmToken(token, user) {
    if (token) {
      console.log('Send FCM token Users Notifications');
      const claims = await this.storageService.get('gnx-claims');
      const emblueToken = await this.emblueService.getToken();

      const token = await this.storageService.get('gnx-token');
      const tokenParcer = this.utilService.parseJwt(token);
      // const docId = tokenParcer?.gluky?.uid ? tokenParcer.gluky.uid : uid;

      const phone = user?.phone ? user?.phone : claims?.phone;
      let user_email =
        user && user.email
          ? user.email
          : 'mobile_' + phone.trim() + '@nomail.com';
      // this.token = token;
      // this.tokenPush = this.fcmService.tokenFcm;
      var phone_user = this.emblueService.validationPhone(phone);
      let editCustomFields =
        'telefono_1:|:' +
        phone_user +
        ':|:1|||nombre:|:' +
        user?.name +
        ':|:1|||apellido:|:' +
        user.lastnames +
        ':|:1|||id_contacto:|:' +
        user.gnxCredential1 +
        ':|:1|||email_secundario:|:' +
        user_email +
        ':|:1|||push_fb:|:' +
        token +
        ':|:1';
      return this.http.post(
        `${environment.pathNotification}/frontend/user/${claims.uid}/register-token`,
        {
          device: navigator.userAgent,
          fcmToken: token,
          customFields: editCustomFields,
        }
      );
    }
  }

  public getFirebaseSALM(): Promise<any> {
    console.log('getFirebaseSALM');
    const provider = new firebase.auth.SAMLAuthProvider(
      environment.saml.provider
    );
    console.log('firebase.auth().currentUser: ', firebase.auth().currentUser);
    //return null;
    if (firebase.auth().currentUser == null) {
      return firebase
        .auth()
        .signInWithPopup(provider)
        .then(async (result) => {
          console.log('result signInWithPopup SAMLAuthProvider', result);
          let getIdToken = result.user.getIdToken();
          let idTokenResult = await result.user
            .getIdTokenResult()
            .then((res) => {
              // console.log("res", res);
              return res;
            });
          // User is signed in.
          // Identity provider data available in result.additionalUserInfo.profile,
          // or from the user's ID token obtained from result.user.getIdToken()
          // as an object in the firebase.sign_in_attributes custom claim
          // This is also available from result.user.getIdTokenResult()
          // idTokenResult.claims.firebase.sign_in_attributes.
          console.log('getIdToken: ', getIdToken);
          console.log('idTokenResult: ', idTokenResult);
          return idTokenResult;
        })
        .catch((error) => {
          // Handle error.
          console.log('ERROR SAMLAuthProvider', error);
        });
    } else {
      console.log('entro al else reauthenticateWithPopup');
      // Reauthenticate with a popup.
      return firebase
        .auth()
        .currentUser.reauthenticateWithPopup(provider)
        .then(async (result) => {
          // Get the updated ID token.
          console.log('token', result, result.user.getIdTokenResult());
          return result.user.getIdTokenResult();
        })
        .then((idTokenResult) => {
          // idTokenResult.authTime should be updated to reflect recent sign-in status.
          // idTokenResult.token has the latest ID token.
          console.log('idTokenResult', idTokenResult.token);
          return idTokenResult;
        })
        .catch((error) => {
          // Handle error.
          console.log('error reauthenticateWithPopup', error);
        });
    }
  }
}
