import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Plugins,Capacitor } from '@capacitor/core';
import { SQLiteConnection,CapacitorSQLite,CapacitorSQLiteWeb,capSQLiteValues } from '@capacitor-community/sqlite';
import { JsonSQLite } from 'jeep-sqlite/dist/types/interfaces/interfaces';
import { BehaviorSubject } from 'rxjs';
import { SelectTable, DataField } from 'src/app/interfaces/exhibition-form/select-table';
import { v4 as uuidv4 } from 'uuid';
import { AlertService } from "src/app/services/alert/alert.service";
import { ExhibitionsService } from '../exhibitions/exhibitions.service';
const {  Device, Storage} = Plugins;
import { defineCustomElements } from 'jeep-sqlite/loader';
@Injectable({
  providedIn: 'root'
})
export class SqliteService {
  // Observable para comprobar si la base de datos esta lista
  public dbReady: BehaviorSubject<boolean>;
  // Indica si estamos en web
  public isWeb: boolean;
  // Indica si estamos en IOS
  public isIOS: boolean;
  // Nombre de la base de datos
  public dbName: string;

  constructor(
    private http: HttpClient,
    public alertService: AlertService, 
    private exhibitionsService:ExhibitionsService
  ) {
    this.dbReady = new BehaviorSubject(false);
    this.isWeb = false;
    this.isIOS = false;
    this.dbName = "fieldsData";
  }

  async init() {

    // CapacitorSQLite no tiene disponible el metodo requestPermissions pero si existe y es llamable
    const sqlite = CapacitorSQLite as any;
    const info = await Device.getInfo();
    // Si estamos en android, pedimos permiso
    if (info.platform == 'android') {
      try {
        await sqlite.requestPermissions();
      } catch (error) {
        this.alertService.presentAlertWithMessage("Únicos Unilever necesita permisos de almacenamiento para funcionar","Ok")
        console.error("Únicos Unilever necesita permisos para funcionar")
      }
      // Si estamos en web, iniciamos la web store
    } else if (info.platform == 'web') {
      defineCustomElements(window);
      const sqliteWeb = new CapacitorSQLiteWeb()
     
      
      this.isWeb = true;
      //await sqliteWeb.initWebStore();
    } else if (info.platform == 'ios') {
      this.isIOS = true;
    }

    // Arrancamos la base de datos
    this.setupDatabase();

  }

  async setupDatabase() {
    // Obtenemos si ya hemos creado la base de datos
    const dbSetup = await Storage.get({ key: 'first_setup_key' })
    console.log("dbSetup",dbSetup)

    // Sino la hemos creado, descargamos y creamos la base de datos
    if (!dbSetup.value) {
      this.downloadDatabase();
    } else {
      //Nos volvemos a conectar
      this.dbName = await this.getDbName();
      await CapacitorSQLite.createConnection({ database: this.dbName });
      await CapacitorSQLite.open({ database: this.dbName })
      this.dbReady.next(true);
    }

  }
  downloadDatabase(){ 
       // Obtenemos el fichero assets/db/db.json
       this.http.get('assets/db/fieldData.json').subscribe(async (jsonExport: JsonSQLite) => {
  
        const jsonstring = JSON.stringify(jsonExport);
        console.log("dbSetup",jsonstring)
        // Validamos el objeto
        const isValid = await CapacitorSQLite.isJsonValid({ jsonstring });
        console.log("dbSetup",isValid)
        // Si es valido
        if (isValid.result) {
         
  
          // Obtengo el nombre de la base de datos
          this.dbName = jsonExport.database;
          // Lo importo a la base de datos
          await CapacitorSQLite.importFromJson({ jsonstring });
          // Creo y abro una conexion a sqlite
          await CapacitorSQLite.createConnection({ database: this.dbName });
          await CapacitorSQLite.open({ database: this.dbName })
  
          // Marco que ya hemos descargado la base de datos
          await Storage.set({ key: 'first_setup_key', value: '1' })
          // Guardo el nombre de la base de datos
          await Storage.set({ key: 'dbname', value: this.dbName })
  
          // Indico que la base de datos esta lista
          this.dbReady.next(true);

        }
  
      })
   
  }
  async getDbName() {

    if (!this.dbName) {
      const dbname = await Storage.get({ key: 'dbname' })
      if (dbname.value) {
        this.dbName = dbname.value
      }
    }
    return this.dbName;
  }

  async create(fieldsData:DataField) {
    // Sentencia para insertar un registro
    let sql = 'INSERT INTO fieldsData VALUES(?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)';
    // Obtengo la base de datos
    const dbName = await this.getDbName();
    // Ejecutamos la sentencia
    return CapacitorSQLite.executeSet({
      database: dbName,
      set: [
        {
          statement: sql,
          values: [
            uuidv4(),
            fieldsData.name,
            fieldsData.type,
            fieldsData.label_field,
            fieldsData.table,
            fieldsData.image_source,
            fieldsData.source,
            fieldsData.source_field,
            fieldsData.source_col,
            fieldsData.value_col,
            fieldsData.validations,
            fieldsData.hasHelp,
            fieldsData.valueHelp,
            fieldsData.inputProps,
            fieldsData.file_config,
            fieldsData.camera_config,
          ]
        }
      ]
    }).then((changes) => {
      console.log("Leido",changes)
     
      return changes;
    }).catch(err => Promise.reject(err))
  }



  async read() {
    // Sentencia para leer todos los registros
    let sql = 'SELECT * FROM fieldsData';
    // Obtengo la base de datos
    const dbName = await this.getDbName();
    // Ejecutamos la sentencia
    return CapacitorSQLite.query({
      database: dbName,
      statement: sql,
      values: [] // necesario para android
    }).then((response: capSQLiteValues) => {
      let fieldsDatas: any = [];
      console.log("Leido",fieldsDatas)

      // Si es IOS y hay datos, elimino la primera fila
      // Esto se debe a que la primera fila es informacion de las tablas
      if (this.isIOS && response.values.length > 0) {
        response.values.shift();
      }

      // recorremos los datos
      for (let index = 0; index < response.values.length; index++) {
        const data = response.values[index];
        fieldsDatas.push(data.name);
      }
      return fieldsDatas;

    }).catch(err => Promise.reject(err))
  }
  

}
