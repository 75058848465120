import { Injectable } from "@angular/core";
import { PlatformService } from "../platform/platform.service";
import { environment } from "../../../environments/environment";
import { RemoteConfigService } from "../remote-config/remote-config.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class EmblueService {
  platform: "web" | "mobile";
  embluUser: any;
  embluPass: any;
  embluToken: any;
  private apiUrl501 = 'https://httpbin.org/status/501';
  constructor(
    private platformService: PlatformService,
    private remoteConfig: RemoteConfigService,
    private http: HttpClient
  ) {
    // this.remoteConfig.getSettingsEmblueConfig().subscribe((data: any) => {
    //   this.embluToken = data['Token'];
    // });
    //this.checkPlatform();
  }


  async getToken() {
    console.log("EMBLUE GENERATE TOKEN");

    const requestOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
        'Access-Control-Allow-Headers':'Content-Type',
        'Content-Type': 'application/json',
        'X-Skip-Interceptor': ''
      })
    };

    let postData = {}
    console.log("requestOptions", requestOptions)
    return new Promise((resolve, reject) =>
      this.http.post(environment.emblu.endpoint, JSON.stringify(postData), requestOptions)
        .subscribe(data => {
          console.log("RESULTPADO POST EMBLUE", data['Token'],"body->",JSON.stringify(postData), requestOptions);
          resolve((data['Token']) ? data['Token'] : '');
        }, error => {
          console.log("error getToken emblue", error);
          reject(error);
        })
    );
  }
  async setAssociatePushToEmail(email, token, tokenPush) {
    console.log("EMBLUE GENERATE AssociatePushToEmail", email, token, tokenPush);
    // - Email  es el email que corresponde al usuario en emBlue registrado al app.  Como mencionamos en la llamada, el email es el identificador de contacto dentro de una cuenta emBlue.  En caso de no registrar al contacto con el email, pueden simular uno con otro id, como el teléfono:  idcelular_511987654321@fakedomain.xyz
    // - Token es el token temporal de seguridad de la cuenta dentro de emBlue. Se requiere el mismo proceso que para el resto de las llamadas de esta API, es decir, utilizar la función Authenticate (ver documentación aquí)
    // - TokenPush es el token único referido al cliente/dispositivo en FCM.
    const requestOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
        'Access-Control-Allow-Headers':'Content-Type',
        'Content-Type': 'application/json',
        'X-Skip-Interceptor': ''
      })
    };

    let postData = {
      "Email": email,
      "Token": token,
      "TokenPush": tokenPush
    }
    return new Promise((resolve, reject) =>
      this.http.post(environment.emblu.associatePushToEmail, JSON.stringify(postData), requestOptions)
        .subscribe(data => {
          ///console.log("RESULTPADO POST setAssociatePushToEmail ", data);
          resolve(data);
        }, error => {
          console.log("error en la asociación", error);
          reject(error);
        })
    );
  }


  async setNewContact(email, editCustomFields ,token) {
    console.log("EMBLUE setNewContact", email, token, editCustomFields);
    const requestOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
        'Access-Control-Allow-Headers':'Content-Type',
        'Content-Type': 'application/json',
        'X-Skip-Interceptor': ''
      })
    };

    let postData = {
      "Email": email,
      "EditCustomFields": editCustomFields,
      "Token": token
  }
    return new Promise((resolve, reject) =>
      this.http.post(environment.emblu.newContact, JSON.stringify(postData), requestOptions)
        .subscribe(data => {
          //console.log("RESULTPADO POST setNewContact ", data);
          resolve(data);
        }, error => {
          console.log("error setNewContact", error);
          reject(error);
        })
    );
  }



  checkPlatform() {
    if (
      this.platformService.checkPlatform("pwa") ||
      this.platformService.checkPlatform("desktop") ||
      this.platformService.checkPlatform("mobileweb")
    ) {
      this.platform = "web";
      return this.initClevertapWeb();
    }
    this.platform = "mobile";
    return this.initClevertapMobile();
  }

  initClevertapMobile() {
    console.log("mobile");
  }

  initClevertapWeb() {
    console.log("web");
  }
  validationPhone(phone){
    try{
      if(phone){
        var phoneRGEX = /(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/;
        var phoneResult = phoneRGEX.test(phone.trim());
        //console.log("phone: "+ phone + " - " + phoneResult );
    
        if(!phoneResult){
          phone = environment.phoneIndicator + phone;
        }
        //console.log("result", phone);
        return phone;
      }
    }catch(error){
      //console.log("error indicative phone");
      return null;
    }
  }

  getNotImplemented() {
    return this.http.get(this.apiUrl501).pipe(
      catchError((error) => {
        if (error.status === 501) {
          console.error('Not Implemented error:', error);
        }
        return throwError(error);
      })
    );
  }
}
