import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, merge, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { StorageService } from "../storage/storage.service";
import { AuthService } from '../auth/auth.service';
import { RouterService } from "../router/router.service";
@Injectable({
  providedIn: 'root'
})
export class BlankScreenService {

  
  private checkInterval = 20000; // Intervalo de 20 segundos

  constructor(
    private router: Router, 
    private ngZone: NgZone,
    private storageService: StorageService, 
    private authService: AuthService,
    private routerService:RouterService
  ) {
    this.monitorUserActivity();
  }

  private monitorUserActivity() {
    console.log("monitereo")
    this.ngZone.runOutsideAngular(() => {
      merge(
        fromEvent(window, 'mousemove'),
        fromEvent(window, 'scroll'),
        fromEvent(window, 'keydown'),
        fromEvent(window, 'click')
      ).pipe(
        switchMap(() => {
          // console.log("monitoreo",this.checkInterval)
          return timer(this.checkInterval);
        })
      ).subscribe(() => {
        this.ngZone.run(() => this.checkIfScreenIsBlank());
      });
    });
  }

  private checkIfScreenIsBlank() {
    const body = document.body;
    const hasContent = body && body.innerHTML.trim().length > 0;
    console.log("monitereo",body,hasContent)
    if (!hasContent) {
     this.authService.logout()
    }
    // } else{
    //   this.routerService.navigate("app/home", { replaceUrl: true });
    // }
  }
}