import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase';
import { finalize, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { AlertService } from '../alert/alert.service';
import { first } from 'rxjs/operators'
import { v4 as uuidv4 } from 'uuid';
export type Filters = 'accepted' | 'in-progress' | 'rejected';
export type ChallengesTypes = 'challenge' | 'geochallenge';
export type ExhibitsTypes = 'invoice' | 'exhibition';
export type Status = 'idle' | 'pending' | 'resolved' | 'rejected';
export type ItemData = { [field: string]: any } & { status: Filters; uid: firebase.User['uid'] };
export type ListData = { id: string, data: ItemData }[];
import { FilesystemDirectory, Plugins, CameraResultType, Capacitor } from '@capacitor/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { EventsService } from 'src/app/services/events/events.service'
import { forceUpdate } from 'jeep-sqlite/dist/types/stencil-public-runtime';
const { Filesystem,Camera } = Plugins;


@Injectable({
  providedIn: 'root'
})
export class ExhibitionsService {
  apiKey = environment.gluky.apikey;
  appId = environment.gluky.app_id;
  type: ExhibitsTypes = 'exhibition'
  error=false;
  constructor(private firestore: AngularFirestore,
    private storageServices: StorageService,
    private alertService:AlertService,
    private storage: AngularFireStorage,
    private events: EventsService,
    private http: HttpClient) { }

  newId() {
    return firebase.firestore().collection('temp').doc().id;
  }

  saveExhibition(data) {
    data.createAt= new Date();
    data.timestamp= new Date();
    data.statusHistory= {
            "in-progress": new Date()
          };
    data.date= new Date();   
    console.log("lo que va a guardar", data, data.uid)
    var timestamp = new Date().getTime();
    const ID = timestamp.toString()
    const docRef = this.firestore.collection(`/ChallengeUsers/${data.uid}/Exhibitions/${data.uid}/Answers/`).doc(ID);
    docRef.set(data);
    return ID
  }

  async getExhibitions()  {
    const claims = await this.storageServices.get("gnx-claims")
    const data = this.firestore
      .collection(`/ChallengeUsers/${claims.uid}/Exhibitions/${claims.uid}/Answers/`, ref => ref.where('uid', '==', claims.uid)
      .orderBy('dateString','desc')
      )
      .snapshotChanges()
      .pipe(map(
        acitons => acitons.map(a => {
          const data: any = a.payload.doc.data();
          const idRef = a.payload.doc.id;

          return { idRef, ...data };
        })
      ))
    return data;
  };

  async getDetail(id) {
    const claims = await this.storageServices.get("gnx-claims");
    return await this.firestore
      .collection(`/ChallengeUsers/${claims.uid}/Exhibitions/${claims.uid}/Answers/`)
      .ref
      .doc(id)
      .get()
      .then(data => {
        if (data.exists) {
          return data.data();
        }
      })
  }

  getStoreName(search, filter?){
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return new Promise((resolve, reject) =>
      this.http.get(`${environment.gluky.algoliaSearchUrl}/exh_stores?search=${search}`, requestOptions)
        .subscribe(data => {
          console.log("resultado", data);
          resolve(data);
        }, error => {
          console.log("cdc error", error);
          reject(error);
        })
    );
  }

  async getfieldData(){
    const token = await this.storageServices.get("gnx-token");
    const claims = await this.storageServices.get("gnx-claims");
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.gluky.exhibitionData}/form`,
        {
          headers
        })
        .subscribe((res) => {
          resolve(res);
        },
          (err) => {
            reject(err);
          });
    })
  }
  // BUSCAR LOS QUE TIENE COLECCION
  async getfieldTableCollecion(){
    const token = await this.storageServices.get("gnx-token");
    const claims = await this.storageServices.get("gnx-claims");
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.gluky.exhibitionData}/tables`,
        {
          headers
        })
        .subscribe((res) => {
          resolve(res);
        },
          (err) => {
            reject(err);
          });
    })
  }
    // BUSCAR Las COLECCION existentes con su valores
    async getfielTabledDataCollecion(id){
      const token = await this.storageServices.get("gnx-token");
      const claims = await this.storageServices.get("gnx-claims");
      const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
      return new Promise((resolve, reject) => {
        this.http.get(`${environment.gluky.exhibitionData}/tables/${id}`,
          {
            headers
          })
          .subscribe((res) => {
            resolve(res);
          },
            (err) => {
              reject(err);
            });
      })
    }
  /// funcion para sincronizar los datos de la exhibiciones
  getDataValue(){
    let tablas:any;
    this.storageServices.get("gnx-fieldsData").then(data =>{
      this.alertService.presentAlert("Actualizando nuevos datos de exhibiciones")
     this.getfieldTableCollecion().then(async (tables:any) =>{
        for (let i = 0; i < tables.data.length; i++) {
          console.log("valeData",tables);
          const element = tables.data[i];
           let valeData=   JSON.parse(await this.storageServices.get(`gnx_${element.id}`));
            console.log("valeData",valeData);
            this.getfielTabledDataCollecion(element.id).then( async (table_value:any)=>{
             console.log("valeData",table_value);
                const dataValues = {
                  table:table_value.id,
                  columns:table_value.columns,
                  value: this.orderValueData(table_value.id,table_value.columns,table_value.data),
                  updated_at: table_value.updated_at
                }
                await this.storageServices.set(`gnx_${element.id}`,JSON.stringify(dataValues))
              
            })
        }
     })
    })
  }

  // ordenamos los datos
  orderValueData(name:string,columns:any,data:any){
    console.log("valeData",data)
    let orderDatas= [];
    let longString: number=0
    if(data.length>=14681){
      longString= 14681
    }else{
      longString=data.length
    }
    for (let i = 0; i < longString; i++) {
      const element = data[i];
      if(name=="clientes" ||name=="store_name"){
      let  orderC= {
        numero: element[0],
          cliente:element[1], 
          tipo:element[2]
        }
      orderDatas.push(orderC)
      } else{
        let  orderC= {
          numero: element[0],
          tipo:element[1], 
          name:element[2]
        }
        orderDatas.push(orderC)
      }
    }
    return orderDatas
  }


  //imagenes

  async changeToBase64(fileName){
    const readFile = await Filesystem.readFile({
      path: fileName,
      directory: FilesystemDirectory.Documents,
    });
   ;
    return `data:image/png;base64,${readFile.data}`
  }

  async deleteImg(fileName){
    await Filesystem.deleteFile({
      path: fileName,
      directory: FilesystemDirectory.Documents,
    });
   
  }

  async deleteAllImg(){
    let directoryName= "DOCUMENTS";
    try {
      // Lista los archivos en el directorio para eliminarlos
      const result = await Filesystem.readdir({
        path: directoryName,
         directory: FilesystemDirectory.Documents,
      });
  
      // Eliminar cada archivo en el directorio
      for (const file of result.files) {
        await Filesystem.deleteFile({
          path: `${directoryName}/${file}`,
           directory: FilesystemDirectory.Documents,
        });
      }
  
      // Finalmente, eliminar el directorio
      await Filesystem.rmdir({
        path: directoryName,
         directory: FilesystemDirectory.Documents,
        recursive: true, // Cambiar a true si hay subdirectorios
      });
  
      console.log(`Directory ${directoryName} deleted successfully`);
    } catch (error) {
      console.error(`Error deleting directory ${directoryName}:`, error);
    }
  }

  //sincronizar ejecuciones
  //1 transformar la url
  async exhibitionsPhotoUrl(photos,exhibition,indexExH,nroPhotos){
    let imagesUrl=[];
    let isPhoto=true;
    let typeArray="camera"
    for (let index = 0; index < photos.length; index++) {
      
      const photo = photos[index];
      console.log(photo)
      let photoFileName = uuidv4();

      const ext = /[.]/.exec(photoFileName) ? /[^.]+$/.exec(photoFileName).pop() : "png";
      const filePath =`users/${exhibition.uid
      }/exhibition/${photoFileName}.${ext}`;

      if (photo.value!=='') {
        const fileRef = this.storage.ref(filePath);
        const task = fileRef.putString(photo.value,'data_url', {contentType:`image/jpg`});
        task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            const downloadURL = fileRef.getDownloadURL();
            downloadURL.subscribe((url) => {
              if (url) {
                if (isPhoto && typeArray=="camera") {
                  const body = {
                    'type': 'camera',
                    'value': url,
                    field_type: "photo_checkbox"
                  };
                  console.log("La ulr es")
                  return imagesUrl.push(body);
                }else if(isPhoto && typeArray!=="camera"){
                  
                    const body = {
                      'type': 'camera',
                      'value': url,
                      'field_name': photo.field_name,
                      'field_name_value':photo.field_name_value,
                      field_type: "photo_checkbox"
                    };
                    return imagesUrl.push(body);
                }else{
                    const body = {
                      'type': 'file',
                      'value': url
                    }
                    return imagesUrl.push(body);
                }
              }
            });
          })
        )
        .subscribe((url) => {
          try {
            if (url) {
              console.log("URL posicion",imagesUrl);
              this.setInfoFiles(url,index,imagesUrl,nroPhotos,exhibition,indexExH)
            }
          } catch (error) {
            this.error=true;
            console.log('Error al cargar el archivo', error);
          }
        });
      }
    }
  }  
 
  setInfoFiles(url,index,imagesUrl,photoN,exhibition,indexExH){
    let numero =photoN -1;
   
     if (url.state == 'success') {
      if(index == numero){
        console.log(index,indexExH )
        exhibition.photos=[];
        exhibition.photos=imagesUrl;
        this.saveExhibitionFinally(exhibition)
      }
    }
  }

  async saveExhibitionFinally(exhibition) {
    
    setTimeout(async () => {
      console.log(exhibition)
      //this.deleteAllImg()
      this.saveExhibition(exhibition)
      this.storageServices.remove("gnx_localData");
        this.events.publish("exhibition:created");
        this.alertService.presentAlert('Ejecuciones actualizadas');
    }, 3000);
  }


  

}


