import { TagsService } from './../tags/tags.service';
import { filter, pairwise } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Router, NavigationExtras, RoutesRecognized } from "@angular/router";
import { ModalController, NavController, Platform } from "@ionic/angular";
import { getTagsConfigRouters } from '../tags/tagsConfig';

@Injectable({
  providedIn: "root",
})
export class RouterService {
  modalWeb: HTMLIonModalElement;
  private currentUrl;
  private previousUrl


  constructor(public router: Router,
    private navCtrl: NavController,
    private platform: Platform,
    public modalController: ModalController,
    private tagsService : TagsService
    ){
      this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      ).subscribe((e: RoutesRecognized[]) => {
        this.previousUrl = e[0].urlAfterRedirects
        this.currentUrl = e[1].urlAfterRedirects 
        this.setTags();
      });
    }

    setTags(){
      const currentUrl = this.currentUrl
      console.debug('SeT tags current = ', currentUrl);
      const configTags = getTagsConfigRouters(currentUrl);
      if(configTags){
        this.tagsService.setEventTag(configTags.page, configTags.EventAnalitycs, configTags.analitycs, true).then(() => {
          console.log('Set tags success', configTags.EventAnalitycs);
        }).catch(error => {
          console.error('Set tags error', error);
        })
      }
    }

  redirect(route:any, modalComponent?:any, modalData?:any, modalClass?:any, modalComponentTwo?:any, id?:any) {
    if (modalComponent && this.platform.is("desktop")) {
      this.openPopup(modalComponent, modalClass, modalData, modalComponentTwo, id);
    } else{
          this.router.navigate([route],{replaceUrl: false});
    }

    }
  async openPopup(component:any, cssClass:any, data?:any, componentToOpen?:any, id?:any) {
    if (this.router.url === "/login") {
        return;
      }
      const modalsOpen = await this.modalController.getTop();
      if (modalsOpen) {
        await modalsOpen.dismiss();
      }
      this.modalWeb = await this.modalController.create({
        component: component,
        cssClass: cssClass,
        componentProps: data,
        showBackdrop: true,
        id: id
      });
      if (componentToOpen) {
        this.openSecondPopup(this.modalWeb, componentToOpen);
      }
      this.modalWeb.onDidDismiss().then(() => {
        document.getElementById(id).remove();
      });
      return await this.modalWeb.present();

  }
  navigate(link: string, extras?: NavigationExtras) {
    this.router.navigate([link], extras);
  }
  redirectRootWithdata(route: string, data: any) {
    this.navCtrl.navigateRoot([route], {
      queryParams: { data: JSON.stringify(data) },
    });
  }
  redirectRoot(route:any, modalComponent?:any, modalData?:any, modalClass?:any) {
    console.log( this.platform.platforms());
    this.modalController.dismiss();
    if (modalComponent && (this.platform.is("desktop") || this.platform.is("mobileweb") || this.platform.is("pwa"))) {
      this.openPopup(modalComponent, modalClass, modalData);
    } else {
      this.navCtrl.navigateRoot([route]);
    }
  }
  redirectWithdata(route: string, data: any, modalComponent?:any, modalData?:any, modalClass?:any) {
    this.modalController.dismiss();
    if (modalComponent && (this.platform.is("desktop") || this.platform.is("mobileweb") || this.platform.is("pwa"))) {
      this.openPopup(modalComponent, modalClass, modalData);
    } else {
      this.router.navigate([route], {
        queryParams: { data: JSON.stringify(data) },
      });
    }
  }
  redirectBack() {
    this.navCtrl.back();
  }

  openSecondPopup(modal:any, component:any) {
    modal.onDidDismiss().then(() => {
      this.openPopup(component, "gnx-modal-radius");
    });
  }

  public getCurrentUrl(){
    return this.currentUrl ? this.currentUrl : this.router.url
  }  

  public getPreviousUrl(){
    return this.previousUrl;
  }  
  Out(url){
    this.router.navigate([url]);
  }
}
