import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage/storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class KpiService {

  baseUrl = environment.gluky.kpiFrontApiBasePath
  complementRoute = '/front/users/'
  apiKey = environment.gluky.apikey

  constructor(private firestore: AngularFirestore, private http: HttpClient, private storageService: StorageService) { }

  async getDetailGraph(idgraph) {
    const markers = [];
    return await this.firestore
      .collection("kpi")
      .ref.where('idgraph', '==', idgraph)
      .get()
      .then(data => {
        data.forEach(function (doc) {
          markers.push(doc.data());
        });
        return markers;
      })
  }

  async getKpis(user, cant = 10) {
    const markers = [];
    return await this.firestore
      .collection("kpi")
      .ref.where('user', '==', user)
      .where("visibility", "==", 'visible')
      .limit(cant).orderBy("date", "desc")
      .get()
      .then(data => {
        data.forEach(function (doc) {
          markers.push(doc.data());
        });
        return markers;
      })
  }

  async getKpisUrl(id?) {
   
    const claims = await this.storageService.get("gnx-claims");
    const route = id ? `${this.baseUrl}${this.complementRoute}${claims.uid}/kpis/${id}`
      : `${this.baseUrl}${this.complementRoute}${claims.uid}/kpis`
    return this.http.get(route)
  }

  async getCategoriesKpisUrl() {
    const claims = await this.storageService.get("gnx-claims");
    return this.http.get(`${this.baseUrl}${this.complementRoute}${claims.uid}/kpi-categories`)
  }

  async getKpisByCategory(categoryId) {
    const claims = await this.storageService.get("gnx-claims");
    const token = await this.storageService.get("gnx-token");
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'X-Skip-Interceptor': '',
      'Content-Type': 'application/json'
    });

    return this.http.get(`${this.baseUrl}${this.complementRoute}${claims.uid}/kpis?key=${this.apiKey}&category_id=${categoryId}`, { headers })
  }

  async getRankingKpi(idKpi, typeRanking) {
    const claims = await this.storageService.get("gnx-claims");
    return this.http.get(`${this.baseUrl}${this.complementRoute}${claims.uid}/kpis/${idKpi}/ranking/${typeRanking}`)
  }

  async getImagesUser(idsUsers) : Promise<{ id: string, image: string }[]> {
    console.log(idsUsers);
    return new Promise(async (resolve, reject) => {
      let result: { id: string, image: string }[] = []
      const refs: any[] = idsUsers.map(id => this.firestore.doc(`user-info/${id}`).ref.get())
      return Promise.all([...refs]).then((data)=>{
        data.forEach(async (data) => {
          const element = await data
          if (element.exists) {
            const object = { id: element.id, image: element.data().urlAvatar }
            result.push(object);
          }
        })
        resolve(result);
      }).catch((err)=>reject(err))
     
    })
  }

    async getCategoriesSectionsKpis() {
    
    const claims = await this.storageService.get("gnx-claims");
    return this.http.get(`${this.baseUrl}${this.complementRoute}${claims.uid}/kpi-category-sections`)/* , { headers } */
  }


}