import { RedemptionService } from 'src/app/services/redemption/redemption.service';
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
//Services
import { UserService } from 'src/app/services/user/user.service';
import { RouterService } from 'src/app/services/router/router.service';
import firebase from 'firebase/app';
import { LanguagesService } from 'src/app/services/languagesService/languages.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Platform } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert/alert.service';
import { GamificationStatusPage } from 'src/app/pages/private/gamification-status/gamification-status.page';
import { RemoteConfigService } from 'src/app/services/remote-config/remote-config.service';
import { CanExchangeService } from 'src/app/services/can-exchange/can-exchange.service';
import { CanExChange } from 'src/app/interfaces/ICanExchange';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-profile-component',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Input() shape: string = 'false';
  @Input() points: string = '0';
  @Input() enableRedemption: boolean = false;
  @Input() set isRedemptionActive(value) {
    this.isActive = value;
    this.iconLockRedemption = value
      ? '../../../assets/icons/home/open_lock.svg'
      : '../../../assets/icons/home/close_lock.svg';
  }
  isActive = false;
  public name: string;
  hideGamification = false;
  loader: boolean = false;
  data: any = {};
  db: any;
  pointsVariable: string;
  program: any;
  programs: any;
  color: string;
  iconLockRedemption = '';
  canExchange: any;
  padlock = false;
  rewardsCode: string | number;

  constructor(
    private userService: UserService,
    private routerService: RouterService,
    private languageService: LanguagesService,
    public authService: AuthService,
    private analitycsService: AnalyticsService,
    public platform: Platform,
    public alertService: AlertService,
    public remoteConfig: RemoteConfigService,
    private redemptionService: RedemptionService,
    private alertController: AlertController,
    private canExchangeService: CanExchangeService,
    private localStorage: StorageService
  ) {
    this.db = firebase;
    const sizeWidth = document.body.clientWidth;
    if (sizeWidth > 576) {
      this.color = 'white';
    }
  }

  ngOnInit() {
    this.getDataProfileField();
    this.canExchanges();
    this.loader = true;
    this.getDataFromUser();
    this.getHideGamification();
    if (this.authService.programId && this.authService.programId[0]) {
      this.programs = this.authService.programId[0].split('/');
      this.program = this.programs.slice(-1).pop();
    }
    this.getInfoUser();
  }

  async getDataProfileField() {
    const dataProfile = await this.localStorage.get('gnx-claims');
    this.rewardsCode = dataProfile.profile?.codigorewards[0];
  }

  async canExchanges() {
    this.canExchange = await this.canExchangeService.getCanExchange();
    this.canExchange = this.canExchange.code;
    if (this.canExchange === CanExChange.EXCHANGE_DISABLED_BY_PADLOCK) {
      this.padlock = true;
    }
    return;
  }

  async getInfoUser() {
    this.userService.getUserInfo().subscribe((res: any) => {
      if (res) {
        this.name = res.name + ' ' + res.lastnames;
      }
    });
  }

  get getTextIUCurrency() {
    return this.languageService.textsIU.currency_data;
  }

  async getDataFromUser() {
    this.userService.getUserInfo().subscribe((user: any) => {
      if (user) {
        this.checkIfUserOldAddress(user);
        this.loader = false;
        this.analitycsService.setUser(this.authService.userGnxId);
        return;
      }
    });
  }

  // this is de old keys for adress, temporal for old programs
  async checkIfUserOldAddress(user) {
    if (user.adress) {
      await this.userService.removeFields('adress');
    }
    if (user.activeAdress) {
      await this.userService.removeFields('activeAdress');
    }
  }

  levelResumeSelected(value) {
    this.routerService.redirect(
      `gamification-status`,
      GamificationStatusPage,
      null,
      'gnx-modal-radius'
    );
  }
  colorText() {
    const sizeWidth = document.body.clientWidth;

    if (sizeWidth > 576) return 'white';

    return '';
  }

  async getHideGamification() {
    return this.remoteConfig.getHideGamification().subscribe((res) => {
      this.hideGamification = res;
    });
  }

  async infoLock() {
    return this.canExchangeService.openModal('exchange');
  }

  async openRedemptionInfo() {
    if (this.isActive) return;
    this.redemptionService.openModal('profile');
  }
}
